import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Menu_buttons } from '../../components/menu_button/menu_button'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch } from '../../store/hooks'
import { useTranslation } from 'react-i18next'
import UserMenuHeader from '../../components/user-menu-header'

import './styles.scss'
import { getCurrentLanguagePath } from '../../translation/getCurrentLanguagePath'

const MenuScreen = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { t } = useTranslation()
    const pathLang = getCurrentLanguagePath()

    const isProduction = process.env.NODE_ENV === 'production' 

    useEffect(() => {
        if (!window.location.hash.includes('brand')) {
            dispatch(changeScreen('Menu Screen'))
        } else {
            dispatch(changeScreen('Corporate Details'))
        }
    }, [window.location.hash, params.id])

    const _closeModal = () => {
        navigate(`/${pathLang}`)
    }

    const _nextScreen = (screen: string) => {
        dispatch(changeScreen(screen))
    }

    return (
        <div className="menu">
            <UserMenuHeader onClose={_closeModal} />

            <div className="menu__buttons-container">
                <Menu_buttons
                    text={t('my_pension')}
                    onClick={() => {
                        _nextScreen('My Impact')
                    }}
                />
                <Menu_buttons
                    text={t('transactions')}
                    onClick={() => {
                        _nextScreen('Transaction Screen')
                    }}
                />
                
                <Menu_buttons
                    text={t('investment_account')}
                    onClick={() => {
                        _nextScreen('Investment Screen')
                    }}
                    disabled={isProduction} 
                />

                <Menu_buttons
                    text={t('following')}
                    onClick={() => {
                        _nextScreen('Following')
                    }}
                />
                <Menu_buttons
                    text={t('Ambassador')}
                    onClick={() => {
                        _nextScreen('Ambassador')
                    }}
                />
                
                <Menu_buttons
                    text={t('feedback')}
                    onClick={() => {
                        window.location.href = 'mailto:info@pai.social'
                    }}
                />
                <Menu_buttons
                    text={t('settings')}
                    onClick={() => {
                        _nextScreen('Settings')
                    }}
                />
                <Menu_buttons
                    text={t('faq')}
                    onClick={() => {
                        _nextScreen('FAQ')
                    }}
                />
            </div>
        </div>
    )
}

export default MenuScreen
