import React, { useState } from 'react';
import MenuHeader from '../../components/menu-header';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store/hooks';


const InvestmentAccountSignedIn: React.FC = () => {
  const { access_token } = useAppSelector((state) => state.auth)

  const { t } = useTranslation();
  return (
    <div className="fondo-signed-in-container">
      <MenuHeader title="Investment Account" />
      <div className='fondo-signed-in-container__description'>
            <p>
                {t(`Fonden är
                en aktivt förvaltad värdepappersfond som genom investeringar i olika
                tillgångsslag och strategier avser att ge investeraren en stabil värdeökning
                över tid med ett så lågt ackumulerat värdefall som möjligt. Fonden
                investerar globalt och har exponering mot flera marknader, sektorer och
                industrier. Ett väldiversifierat tillvägagångsätt gör att fondens
                kurssvängningar reduceras utan att avkastningen påverkas negativt. Fondens
                målsättning är att generera en god riskjusterad avkastning oavsett marknadsläge.`)}
            </p>    
        </div>    
    </div>
  );
};

export default InvestmentAccountSignedIn;
