import { useState } from 'react'
import './styles.scss'

import close from '../../assets/images/svgs/close.svg'
import { Button } from '../../components/button'
import { PasswordField } from '../../components/password-field'
import TextField from '../../components/text-field'
import { getAccessTokenSignIn } from '../../services/api'
import { CLIENT_ID, CLIENT_SECRET } from '../../services/config'
import { changeScreen, setEmail } from '../../store/auth/auth-action'
import { useAppDispatch } from '../../store/hooks'
import { validateEmail } from '../../utils/_validateEmail'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'

const SignIn = () => {
    const [email, updateEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const _closeModal = () => {
        dispatch(changeScreen('Start Screen'))
    }

    const _signIn = async () => {
        if (!email.trim() || !password.trim()) {
            setError('Please fill all fields')
        } else if (!validateEmail(email)) {
            setError('Incorrect email')
        } else {
            const isSignedIn = await getAccessTokenSignIn(
                {
                    client_id: CLIENT_ID,
                    client_secret: CLIENT_SECRET,
                    grant_type: 'password',
                    scope: 'ios',
                    username: email,
                    password: password,
                },
                setError
            )           
            if(isSignedIn) {
                navigate(location.pathname);
            }
        }
    }
    const _setvalue = (SetState: (e: string) => void, e: string) => {
        SetState(e)
        setError('')
    }
    const _forgotPassword = () => {
        dispatch(setEmail(email));
        dispatch(changeScreen('Forgot Password'))
    }
    return (
        <div className="sign-in-container">
            <div
                className="sign-in-container__close-wrapper"
                onClick={_closeModal}
            >
                <img src={close} />
            </div>
            <div className="sign-in-container__body">
                <h3 className="sign-in-container_title">{t('sign_in')}</h3>
                <div className="sign-in-container__form-container">
                    <TextField
                        value={email}
                        setValue={(e: string) => _setvalue(updateEmail, e)}
                        label={t('email')}
                    />
                    <PasswordField
                        value={password}
                        setValue={(e: string) => _setvalue(setPassword, e)}
                        label={t('password')}
                    />
                    {error && (
                        <p style={{ color: 'red', fontWeight: 'bold' }}>
                            {error}
                        </p>
                    )}
                </div>
                <div className="sign-in-container__bottom-container">
                    <Button
                        variant="white-outlined"
                        text={t('next')}
                        onClick={_signIn}
                    />
                </div>
            </div>
            <div className="sign-in-container__forgot-container">
                <span
                    className="sign-in-container_forgot-title"
                    onClick={_forgotPassword}
                >
                    {t('forgot_password')}
                </span>
            </div>
        </div>
    )
}

export default SignIn
