import arrow_back from '../../assets/images/svgs/arrow-back-black.svg'
import { Button } from '../../components/button'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch } from '../../store/hooks'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import './styles.scss'

const FinishSignUp = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const _goBack = () => {
        dispatch(changeScreen('Sign Up'))
    }

    const _signUp = () => {
        navigate(location.pathname)
        // dispatch(changeScreen('Select Interest'))
        dispatch(changeScreen('Menu Screen'))

    }
    return (
        <div className="finish-container">
            <div className="finish-container__close-wrapper" onClick={_goBack}>
                <img src={arrow_back} />
            </div>
            <div className="finish-container__body">
                <div className="finish-container__content">
                    <h3 className="finish-container_title">
                        {t('finish_signing_up')}
                    </h3>
                    <p className="finish-container_info">
                        {t('by_tapping_sign_up')}{' '}
                        <a
                            href="https://www.ample.earth/terms-of-use"
                            rel="noopener noreferrer"
                            target="_blank"
                            className="finish-container_underline"
                        >
                            {t('terms')}.
                        </a>{' '}
                        {t('learn_how_we_collect')}{' '}
                        <span>{t('data_policy')}</span>{' '}
                        {t('and_how_we_use_cookies')}{' '}
                        <span>{t('cookie_policy')}</span>.
                    </p>
                    <div className="finish-container__button-wrapper">
                        <Button
                            variant="white-outlined"
                            onClick={_signUp}
                            text={t('sign_up')}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinishSignUp
