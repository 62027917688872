import { useEffect, useRef, useState } from 'react'
import arrowBack from '../../assets/images/svgs/arrow-black.svg'
import arrowUp from '../../assets/images/arrow-up.png'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setInProject } from '../../store/impact/impact-action'
import { User } from '../../types/api'
import { useTranslation } from 'react-i18next'
import { Chart, registerables } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import { getUserSavings } from '../../services/api'
import { UserSavings } from '../../types/api'
import Spinner from '../../components/spinner'
import './styles.scss'
import MenuHeader from '../../components/menu-header'
import MyContributions from '../../components/my-contributions'

const MyImpact = () => {
    const access_token = useAppSelector((state) => state.auth.access_token)
    const { inProject } = useAppSelector((state) => state.impact)
    const [isLoading, setIsLoading] = useState(true)
    const [savings, setSavings] = useState<UserSavings>()
    const [showContent, setShowContent] = useState("Rewards")
    const [activeTab, setActiveTab] = useState(
        inProject ? 'Projects' : 'Impact'
    )
    const [currentYear, setCurrentYear] = useState<number>(
        new Date().getFullYear()
    )

    const dispatch = useAppDispatch()
    const _setActiveTab = (tab: string) => {
        setActiveTab(tab)
    }
    const { t } = useTranslation()
    const chartRef = useRef<HTMLCanvasElement | null>(null)

    useEffect(() => {
        if (chartRef.current && savings && showContent === "Rewards") {
            Chart.register(...registerables, annotationPlugin);
    
            const existingChart = Chart.getChart(chartRef.current);
            if (existingChart) {
                existingChart.destroy();
            }
    
            const ctx = chartRef.current.getContext('2d');
            if (ctx) {
                const monthlyKeys = Object.keys(savings.monthly);
                const monthlyValues = Object.values(savings.monthly).map((value) => value / 100);
                const labels = monthlyKeys.map((month) => month.charAt(0));
                const average = savings.monthly_average / 100;
    
                new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: labels,
                        datasets: [
                            {
                                data: monthlyValues,
                                backgroundColor: '#ffa9a3',
                                borderWidth: 0,
                                borderRadius: 7,
                                hoverBackgroundColor: '#7aa0b5',
                                barThickness: 14,
                            },
                        ],
                    },
                    options: {
                        scales: {
                            y: {
                                beginAtZero: true,
                                grid: {
                                    display: false,
                                },
                            },
                            x: {
                                grid: {
                                    display: false,
                                },
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            annotation: {
                                annotations: {
                                    line1: {
                                        type: 'line',
                                        yMin: average,
                                        yMax: average,
                                        borderColor: '#7aa0b5',
                                        borderWidth: 2,
                                        borderDash: [3, 3],
                                        label: {
                                            content: `Average: ${average.toFixed(2)}`,
                                            position: 'center',
                                            drawTime: 'afterDraw',
                                            display: false,
                                        },
                                        enter(ctx: any, event: any) {
                                            const annotation = ctx.chart.config.options.plugins.annotation.annotations.line1;
                                            annotation.label.display = true;
                                            ctx.chart.update();
                                        },
                                        leave(ctx: any) {
                                            const annotation = ctx.chart.config.options.plugins.annotation.annotations.line1;
                                            annotation.label.display = false;
                                            ctx.chart.update();
                                        },
                                    },
                                },
                            },
                        },
                        hover: {
                            mode: 'nearest',
                            intersect: true,
                        },
                    },
                });
            }
        }
    }, [savings, showContent]); 
    

    useEffect(() => {
        const fetchUserSavings = async () => {
            setIsLoading(true)
            try {
                const data = await getUserSavings(
                    access_token.access_token,
                    currentYear
                )
                setSavings(data)
            } catch (error) {
                console.error('Error fetching user savings:', error)
            } finally {
                setIsLoading(false)
            }
        }

        fetchUserSavings()
    }, [currentYear])

    const calculateIncomeHandler = () => {
        dispatch(changeScreen('Calculating income'))
    }

    return (
        <div className="impact-container">
            <MenuHeader title={t('my_savings').toUpperCase()} />
    
            <div className="impact-container__content">
                <div className="impact-container__switch-wrapper">
                    <button
                        // className={`impact-container__switcher ${
                        //     activeTab === 'Impact' && 'active-switcher'
                        // }`}
                        className={`impact-container__switcher ${
                            showContent === 'Rewards' ? 'active-switcher' : ''
                        }`}
                        onClick={() => {
                            setShowContent('Rewards');
                            _setActiveTab('Impact');
                            dispatch(setInProject(false));
                        }}
                    >
                        {t('rewards')}
                    </button>
                    <button
                        // className={`impact-container__switcher ${
                        //     activeTab === 'Projects' && 'active-switcher'
                        // }`}
                        className={`impact-container__switcher ${
                            showContent === 'Contributions' ? 'active-switcher' : ''
                        }`}
                        onClick={() => {
                            setShowContent('Contributions');
                        }}
                    >
                        {t('contributions')}
                    </button>
                </div>
                {showContent === "Rewards" ? (
                    isLoading ? (
                        <div className="impact-container__spiner">
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            {/* Statistics */}
                            <div className="impact-container__statistic">
                                <div className="statistic-data">
                                    <h6>{t('current_month')}</h6>
                                    <div className="statistic-row">
                                        <span>
                                            {savings?.current_month
                                                ? savings.current_month / 100
                                                : 0}
                                        </span>
                                        <span>{savings?.currency}</span>
                                    </div>
                                </div>
                                <div className="statistic-data">
                                    <h6>{t('year_to_date')}</h6>
                                    <div className="statistic-row">
                                        <span>
                                            {savings?.year_to_date
                                                ? savings.year_to_date / 100
                                                : 0}
                                        </span>
                                        <span>{savings?.currency}</span>
                                    </div>
                                </div>
    
                                <button
                                    onClick={calculateIncomeHandler}
                                    className="statistic-calculate"
                                >
                                    {t('calculate_pension')} <img src={arrowUp} alt="" />
                                </button>
                            </div>
    
                            {/* Rewards */}
                            <div className="impact-container__rewards">
                                <div className="impact-container__rewards-header">
                                    <h3>{t('monthly_rewards')}</h3>
                                    <div className="impact-container__date-buttons">
                                        <button
                                            onClick={() => setCurrentYear((prev) => prev - 1)}
                                        >
                                            <img src={arrowBack} alt="prev" />
                                        </button>
                                        <span>{currentYear}</span>
                                        <button
                                            onClick={() => setCurrentYear((prev) => prev + 1)}
                                        >
                                            <img
                                                src={arrowBack}
                                                alt="next"
                                                className="arrow-next"
                                            />
                                        </button>
                                    </div>
                                </div>
                                <canvas ref={chartRef}></canvas>
                            </div>
    
                            {/* Total */}
                            <div className="impact-container__total">
                                <h3>{t('total_rewards')}</h3>
                                <div className="impact-container__statistic total-row">
                                    <div className="statistic-row">
                                        <span>
                                            {savings?.total ? savings.total / 100 : 0}
                                        </span>
                                        <span>{savings?.currency}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                ) : (
                    <MyContributions />
                )}
            </div>
        </div>
    );
}

export default MyImpact
