import React, { useState } from 'react';
import { Button } from '../../components/button';
import MenuHeader from '../../components/menu-header';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { getFondoSignupUrl } from '../../services/api';
import { useAppSelector } from '../../store/hooks';


const InvestmentAccount: React.FC = () => {
  const [showSignupFrame, setShowSignupFrame] = useState(false);
  const [signupUrl, setSignupUrl] = useState<string | null>(null);
  const { access_token } = useAppSelector((state) => state.auth)

  const { t } = useTranslation();

  const handleSignupClick = async (access_token: string) => {
    const url = await getFondoSignupUrl(access_token);
    if (url) {
      setSignupUrl(url);
      setShowSignupFrame(true);
    }
  };

  return (
    <div className="fondo-container">
      <MenuHeader title="Investment Account" />
      {showSignupFrame ? (
        <div className='signup-container'>
        <iframe
          className="signup-container__signup-frame"
          src={signupUrl ?? ""}
          title="Fondo Signup"
          width="100%"
          height="90%" 
        />
        </div>
      ) : (
       <>
          
          <div className="fondo-container__description">
            <p>{t("fondo_description1")}</p>
            <br />
            <p>{t("fondo_description2")}</p>
          </div>
          <p className="fondo-container__action-label">register with</p>
          <div className="fondo-container__action">
            <Button
              variant="gasoline-outlined"
              text="Fondo"
              onClick={() => handleSignupClick(access_token.access_token)}
            />
          </div>
          </>
      )}
            </div>

  );
  
  
};

export default InvestmentAccount;
