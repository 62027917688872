import React, { useState } from 'react';
import { Button } from '../../components/button';
import MenuHeader from '../../components/menu-header';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store/hooks';


const FondoSuccess: React.FC = () => {
  const { access_token } = useAppSelector((state) => state.auth)

  const { t } = useTranslation();
  const _handleSilverdomeClick = () => {
    window.open(
      "https://doc.morningstar.com/document/659f62cd05259d6d473e22d59a09e158.msdoc/?clientId=fondo&key=0de25a5891876389",
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <div className="fondo-success-container">
      <MenuHeader title="Investment Account" hideBackArrow={true} />
        <div className='fondo-success-container__description'>
            <p>
                {t(`Congratulations! You completed the sign up for your
                personal investment account successfully! 
                For more information on the fund you will be invested in click here:`)}
            </p>
        </div>
        <div className='fondo-success-container__action'>
            <Button text={t('Silverdome')} onClick={_handleSilverdomeClick} variant='gasoline-outlined'/>
        </div>
    </div>

  );
  
  
};

export default FondoSuccess;
