import { useEffect, useRef, useState } from 'react'

import arrowBack from '../../assets/images/svgs/arrow-black.svg'
import arrowUp from '../../assets/images/arrow-up.png'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setInProject } from '../../store/impact/impact-action'
import { User } from '../../types/api'
import { useTranslation } from 'react-i18next'
import { Chart, registerables } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import { getUserSavings } from '../../services/api'
import { UserSavings } from '../../types/api'
import Spinner from '../../components/spinner'

import './styles.scss'
import SettingsHeader from '../../components/settings-header'
import MenuHeader from '../../components/menu-header'

const MyContributions = () => {
    const access_token = useAppSelector((state) => state.auth.access_token)
    const { inProject } = useAppSelector((state) => state.impact)
    const [isLoading, setIsLoading] = useState(true)
    const [savings, setSavings] = useState<UserSavings>()
    const [activeTab, setActiveTab] = useState(
        inProject ? 'Projects' : 'Impact'
    )
    const [currentYear, setCurrentYear] = useState<number>(
        new Date().getFullYear()
    )

    const dispatch = useAppDispatch()
    const _setActiveTab = (tab: string) => {
        setActiveTab(tab)
    }
    const { t } = useTranslation()
    const chartRef = useRef<HTMLCanvasElement | null>(null)



    return (
            <div className="impact-container-contributions__content">
                {/* {isLoading ? (
                    <div className="impact-container__spiner">
                        <Spinner />
                    </div>
                ) : ( */}
                    <>
                        {/* Statistics */}
                        <div className="impact-container__statistic">
                            <div className="statistic-data">
                                <h6>{t('current_month')}</h6>
                                <div className="statistic-row">
                                    <span>
                                        {savings?.current_month
                                            ? savings.current_month / 100
                                            : 0}
                                    </span>
                                    <span>{savings?.currency}</span>
                                </div>
                            </div>
                            <div className="statistic-data">
                                <h6>{t('year_to_date')}</h6>
                                <div className="statistic-row">
                                    <span>
                                        {savings?.year_to_date
                                            ? savings.year_to_date / 100
                                            : 0}
                                    </span>
                                    <span>{savings?.currency}</span>
                                </div>
                            </div>

                            <button
                                className="statistic-calculate"
                            >
                                {t('calculate_income')}{' '}
                                <img src={arrowUp} alt="" />
                            </button>
                        </div>

                        {/* Rewards */}
                        <div className="impact-container__rewards">
                            <div className="impact-container__rewards-header">
                            <h3>{t('fund_performance')}</h3>
                            <div className="impact-container__date-buttons">
                                    <button
                                        onClick={() =>
                                            setCurrentYear((prev) => prev - 1)
                                        }
                                    >
                                        {<img src={arrowBack} alt="prev" />}
                                    </button>
                                    <span>{currentYear}</span>
                                    <button
                                        onClick={() =>
                                            setCurrentYear((prev) => prev + 1)
                                        }
                                    >
                                        {
                                            <img
                                                src={arrowBack}
                                                alt="prev"
                                                className="arrow-next"
                                            />
                                        }
                                    </button>
                                </div>
                            </div>
                            <canvas
                                ref={chartRef}
                            ></canvas>
                        </div>

                        {/* Total */}
                        <div className="impact-container__total">
                            <h3>{t('total_rewards')}</h3>
                            <div className="impact-container__statistic total-row">
                                <div className="statistic-row">
                                    <span>
                                        {savings?.total
                                            ? savings.total / 100
                                            : 0}
                                    </span>
                                    <span>{savings?.currency}</span>
                                </div>
                            </div>
                        </div>
                    </>
                {/* )} */}
            </div>
    )
}

export default MyContributions
