import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { changeScreen } from '../../store/auth/auth-action';
import { Button } from '../../components/button';
import { User } from '../../types/api';
import MenuHeader from '../../components/menu-header';
import { DeleteAccount } from '../delete-account';


const DeleteAccountProcess: React.FC = () => {
    const dispatch = useAppDispatch();
    const [deleteAccount, setDeleteAccount] = useState(false)
    const user = useAppSelector((state) => state.auth.user) as User;
    const { t } = useTranslation();

    const _goBack = () => {
        dispatch(changeScreen('Settings'));
    };
    const _delete_account = () => {
        setDeleteAccount(true)
    }
  

    return (
<div className="DeleteAcc-container">
    <MenuHeader title={t('Delete Account')}/>
    <div className='DeleteAcc-container__description'>
        <p>
        Please note that deleting your account is irreversible. When you go ahead, your remaining rewards which have not 
        been contributed to your personal investment account will be lost,
        and can not be redeemed at a later stage. You can however still access your investment account 
        by logging in with Fondo directly.
        If you decide to re-open your account, it will be a completely new account as your 
        old user data will be deleted and can not be matched. 
        </p>
    </div>
    <div className='DeleteAcc-container__label'> 
        <p>{t('Do you want to continue to delete your account?')}</p>
    </div>
    <div className='DeleteAcc-container__action'>
        
        <Button variant='gasoline-outlined' onClick={_delete_account} text={t('Yes')}/>
        <Button variant='gasoline-outlined' onClick={_goBack} text={t('No')}/>
    </div>
    {deleteAccount && (
                <DeleteAccount setDeleteAccount={setDeleteAccount} />
            )}
</div>
);
};

export default DeleteAccountProcess;
